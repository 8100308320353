var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { staticClass: "p-3", attrs: { gutter: [16, 16] } },
    [
      _c(
        "a-col",
        { attrs: { sm: 24, md: 12 } },
        [
          _c(
            "a-space",
            [
              _c(
                "a-button",
                {
                  attrs: { icon: "delete", type: "danger" },
                  on: { click: _vm.showConfirmation }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
              ),
              _c(
                "a-button",
                {
                  attrs: { icon: "plus", type: "primary" },
                  on: { click: _vm.handleAddRow }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 24 } },
        [
          _c("TableCustom", {
            attrs: {
              "on-select-change": _vm.onSelectChange,
              "selected-row-keys": _vm.selectedRowKeys,
              "data-source": _vm.dataSource,
              columns: _vm.columns,
              "default-pagination": _vm.defaultPagination,
              "handle-search-select-table": _vm.handleSearchSelectTable,
              "handle-select": _vm.handleSelect,
              paginationcustom: true,
              idtable: "table1",
              scroll: { x: "calc(700px + 50%)" },
              loading: _vm.loadingTable,
              "handle-input": _vm.handleInput
            }
          })
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { sm: 24, md: 12 } },
        [
          _c("Pagination", {
            attrs: {
              total: _vm.totalData,
              "page-size-set": _vm.limit,
              "id-pagination": "pagination1"
            },
            on: {
              "response-pagesize-change": _vm.responsePageSizeChange,
              "response-currentpage-change": _vm.responseCurrentPageChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }